import { BaseFieldProps } from "@/components/form-builder/fields/base/BaseFieldProps";
import { IoCTypes } from "@/IoC/IoCTypes";
import {
    formatMultidDate,
    formattedDate,
    formattedDateTime,
    formattedTime,
    Helpers,
    increaseDate,
    formattedDateTimeReports,
    getYearsbeforeDate
} from "@/lib/Util";
import {
    actions,
    execution,
    RemoteOptions
} from "@/models/FormBuilderModel";
import { FieldOption } from "@/models/ListModel";
import { ILookupService } from "@/services/lookup/ILookpupService";
import {
    ActionSource,
    EventTypes,
    FieldTypes
} from "@/shared/FormBuilderShared";
import { Container } from "inversify";
import {
    computed,
    ComputedRef,
    inject
} from "@vue/runtime-core";
import {
    Options,
    Vue
} from "vue-class-component";
import { ILookpupStoreService } from "@/services/lookup/ILookupStoreService";
import { authorizePermission } from "@/lib/AuthUtil";
import { convertDate } from "@/components/filters/ConvertDate";

type TProvider = {
    fieldDynamicOptions: ComputedRef<FieldOption[]>;
    rules: ComputedRef<string>;
    IsDisabled: ComputedRef<boolean>;
    IsReadOnly: ComputedRef<boolean>;
    onCreateSubTemplates: execution[];
    beforeCreateSubTemplates: execution[];
    executeOnChange: () => any;
    executeOnClick: () => any;
    executeOptionSelected: () => any;
    dynamicOptionsLoading: ComputedRef<boolean>;
    state: ComputedRef<any>,
    handleActionClick: (event: EventTypes, type: ActionSource, executions?: execution[]) => unknown,
    onDestroySubTemplates: execution[]
}

@Options({
    template: `<slot v-if="!IsHide"></slot>`,
    watch: { modelValue() { this.executeOnChanged(); } },
    provide(): TProvider {
        return {
            fieldDynamicOptions: computed(() => this.fieldDynamicOptions),
            rules: computed(() => this.rules),
            IsDisabled: computed(() => this.IsDisabled),
            IsReadOnly: computed(() => this.IsReadOnly),
            onCreateSubTemplates: this.onCreateSubTemplates,
            beforeCreateSubTemplates: this.beforeCreateSubTemplates,
            executeOnChange: this.executeOnChange,
            executeOptionSelected: this.executeOptionSelected,
            executeOnClick: this.executeOnClick,
            dynamicOptionsLoading: computed(() => this.dynamicOptionsLoading),
            state: computed(() => this.state),
            handleActionClick: this.handleActionClick,
            onDestroySubTemplates: this.onDestroySubTemplates,
        }
    }
})
export default class FormFieldHandler extends Vue.with(BaseFieldProps) {
    rules = "";
    executeFunction: (executions: execution[], currentObject?: any) => any = inject("executeFunction") as (executions: execution[], currentObject?: any) => any;
    state: any = {
        fieldDynamicOptions: [],
        dynamicOptionsLoading: false,
        remoteOptions: this.remoteOptions,
        fieldLabel: this.fieldLabel,
        maximumDate: this.maximumDate,
        minimumDate: this.minimumDate,
        validations: this.validations && [...this.validations],
        actions: this.actions && [...this.actions]
    };
    containerState: any = inject("containerState") as any;
    onChangedAction: actions | undefined;
    onChangeAction: actions | undefined;
    onOptionSelectedAction: actions | undefined;
    onClickAction: actions | undefined;
    func = {
        getFormatMultidDate: formatMultidDate,
        getFormattedDate: formattedDate,
        getFormattedTime: formattedTime,
        getFormattedDateTime: formattedDateTime,
        getConvertedDate: convertDate,
        getUTCNowDate: Helpers.getUTCNow,
        increaseDateByNumber: increaseDate,
        getDateDifferenceInYears: Helpers.getDateDifferenceInYears,
        getFormattedDateTimeReports: formattedDateTimeReports,
        getYearsBeforeDate: getYearsbeforeDate
    };
    newGuid = Helpers.NewGuid();
    isParentReadOnly: boolean = inject("IsReadOnly") as boolean;
    IoC: Container = inject("IoC") as Container;
    lookupService!: ILookupService;
    lookupStoreService!: ILookpupStoreService;

    beforeCreate() {
        this.lookupService = this.IoC.get<ILookupService>(IoCTypes.LookupService);
        this.lookupStoreService = this.IoC.get<ILookpupStoreService>(IoCTypes.LookupStoreService);
    }

    created() {
        this.setDefaultValue();
        if ([
            FieldTypes.DROPDOWNREF,
            FieldTypes.MULTICHECKBOXREF,
            FieldTypes.RADIOREF,
            FieldTypes.MULTISELECTDROPDOWNREF,
            FieldTypes.DROPDOWN_TEMPLATE,
            FieldTypes.REPORTCHECKBOXREF,
            FieldTypes.REPORTCHECKBOXNEW
        ].includes(this.fieldType)) {
            if (this.state.remoteOptions) this.lookupService.getLookupDetails(this.state.remoteOptions as RemoteOptions)
        }
        if (this.onCreate) this.executeFunction(this.onCreate, this);
        this.rules = this.populateRules();
        this.loadInputControlActions();
    }

    handleActionClick(_event: EventTypes, _type: ActionSource, executions?: execution[]) {
        if (executions) {
            this.executeFunction(executions, this);
        }
    }

    setDefaultValue() {
        if (this.defaultValue && this.modelValue === undefined) {
            this.$emit("update:modelValue", JSON.parse(JSON.stringify(this.defaultValue)));
        }
    }

    populateRules(): string {
        let rules = "";
        if (!this.isParentReadOnly || !this.IsReadOnly) {
            for (const validation of this.state.validations || []) {
                if (rules !== "") rules += "|";
                if (String(validation.required?.value) === "true") rules += String("required:") + validation.required?.errorText;
                else if (String(validation.autogen?.value) === "true") rules += String("autogen:") + validation.autogen?.errorText;
                else if (String(validation.alphaNumeric?.value) === "true") rules += String("alpha_num:") + validation.alphaNumeric?.errorText;
                else if (String(validation.alphaNumericSpaces?.value) === "true") rules += String("alpha_num_spaces:") + validation.alphaNumericSpaces?.errorText;
                else if (String(validation.alphaNumericSpacesHyphen?.value) === "true") rules += String("alpha_num_spaces_hyphen:") + validation.alphaNumericSpacesHyphen?.errorText;
                else if (String(validation.alpha?.value) === "true") rules += String("alpha:") + validation.alpha?.errorText;
                else if (String(validation.numeric?.value) === "true") rules += String("numeric:") + validation.numeric?.errorText;
                else if (validation.regExpression?.value && String(validation.regExpression?.value) !== "") rules += String("regex:") + String(validation.regExpression?.value) + String(",") + validation.regExpression?.errorText;
                else if (String(validation.futuredate?.value) === "true") rules += String("futuredate:") + validation.futuredate?.errorText;
                else if (String(validation.adultcasedob?.value) === "true") rules += String("adultcasedob:") + validation.adultcasedob?.errorText + (validation.adultcasedob?.givenDate ? (String(",") + this.executeFunction(validation.adultcasedob?.givenDate, this)) : "");
                else if (String(validation.juvenilecasedob?.value) === "true") rules += String("juvenilecasedob:") + validation.juvenilecasedob?.errorText + (validation.juvenilecasedob?.givenDate ? (String(",") + this.executeFunction(validation.juvenilecasedob?.givenDate, this)) : "");
                else if (String(validation.decimal?.value) === "true") rules += String("decimal:") + validation.decimal?.errorText;
                else if (String(validation.height?.value) === "true") rules += String("height:") + validation.height?.errorText;
                else if (String(validation.phone?.value) === "true") rules += String("phone:") + validation.phone?.errorText;

            }
        }

        return rules;
    }

    loadInputControlActions() {
        this.onChangedAction = this.state.actions?.find((x: any) => x.type === ActionSource.INPUT_CONTROL && x.event === EventTypes.CHANGED);
        this.onChangeAction = this.state.actions?.find((x: any) => x.type === ActionSource.INPUT_CONTROL && x.event === EventTypes.CHANGE);
        this.onOptionSelectedAction = this.state.actions?.find((x: any) => x.type === ActionSource.INPUT_CONTROL
            && x.event === EventTypes.OPTION_SELECTED);
        this.onClickAction = this.state.actions?.find((x: any) => x.type === ActionSource.INPUT_CONTROL && x.event === EventTypes.CLICK);
    }

    get fieldDynamicOptions(): FieldOption[] {
        const storeName = this.state.remoteOptions?.storeAlterName === undefined
            ?
            this.state.remoteOptions?.url : this.state.remoteOptions?.storeAlterName;
        if (storeName) return this.lookupStoreService.lookupDetails[storeName]
        return this.state.fieldDynamicOptions;
    }

    get dynamicOptionsLoading(): boolean {
        const storeName = this.state.remoteOptions?.storeAlterName === undefined
            ?
            this.state.remoteOptions?.url : this.state.remoteOptions?.storeAlterName;
        if (storeName) return this.lookupStoreService.lookupDetailsLoading[storeName]
        return this.state.dynamicOptionsLoading;
    }

    get IsDisabled() {
        const isDisabled = this.properties?.isDisabled;
        if (isDisabled === true) return true;
        else if (isDisabled === undefined || isDisabled === false) return false;
        return this.executeFunction(isDisabled, this);
    }
    get IsReadOnly() {
        if (this.isParentReadOnly) return true;
        const editablePermission = this.properties?.editablePermission;
        if (editablePermission) return !authorizePermission(editablePermission)
        const isReadonly = this.properties?.isReadonly;
        if (isReadonly === true) return true;
        else if (isReadonly === undefined || isReadonly === false) return false;
        return this.executeFunction(isReadonly, this);
    }
    get IsHide() {
        const visiblePermission = this.properties?.visiblePermission;
        if (visiblePermission) return !authorizePermission(visiblePermission)
        const isHide = this.properties?.isHide;
        if (isHide === true) return true;
        else if (isHide === undefined || isHide === false) return false;
        return this.executeFunction(isHide, this);
    }
    executeOnChange() {
        if (this.onChangeAction && this.onChangeAction.executions) this.executeFunction(this.onChangeAction.executions, this);
    }
    executeOnChanged() {
        if (this.onChangedAction && this.onChangedAction.executions) this.executeFunction(this.onChangedAction.executions, this);
    }
    executeOnClick() {
        if (this.onClickAction && this.onClickAction.executions) this.executeFunction(this.onClickAction.executions, this);
    }
    executeOptionSelected() {
        if (this.onOptionSelectedAction && this.onOptionSelectedAction.executions) this.executeFunction(this.onOptionSelectedAction.executions, this);
    }
}
