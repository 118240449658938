import * as u4 from "uuid";
import * as lx from "luxon";
import {
    FieldOption,
    IdName
} from "@/models/ListModel";
import CryptoJS from "crypto-js";
import ProfileStore from "@/store/profile/ProfileStore";
import AppStore from "@/store";
import { getModule } from "vuex-module-decorators";

export const convertedDate = (dateVal: any) => {
    const profileStore: ProfileStore = getModule(ProfileStore, AppStore)
    const orgTimezone = profileStore.MyProfile.organisations[0].timezone;
    return new Date((typeof dateVal === "string" ? new Date(dateVal) : dateVal).toLocaleString("en-US", { timeZone: orgTimezone }));
}
export class Helpers {

    /** Generates a new unique identifier */
    static NewGuid(): string {
        return u4.v4();
    }

    static randomString(size: number): string {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        for (let i = 0; i < size; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    /**
     * Fetch a error code details from the dictionary file
     * @param key A valid key from the error dictionary
     */
    static getError(key: string): { code: string, text: string, level: string } {
        const temp: Array<{ code: string, text: string, level: string }> = JSON.parse(atob(localStorage.getItem("PREA_MESSAGE_DICTIONARY") as string));
        return temp.filter(f => f.code.toLowerCase() === key.toLowerCase())[0];
    }


    /**
     * Generates a range of numbers with the given start and end limits
     * @param start Minimum number of the range
     * @param end Maximum number of the range
     */
    static range(start: number, end: number): Array<number> {
        return Array.from(Array(end - start + 1).keys()).map((x) => x + start);
    }

    /**
     * Fetch a message dictionary file from assets
     */
    static loadMessageDict() {
        if (!localStorage.getItem("PREA_MESSAGE_DICTIONARY")) {
            fetch("/assets/configs/message-dict.json", {
                method: "GET",
                mode: "same-origin",
                referrerPolicy: "no-referrer",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            })
                .then((d) => { return d.json(); })
                .then(j => {
                    localStorage.setItem("PREA_MESSAGE_DICTIONARY", btoa(JSON.stringify(j)));
                });
        }
    }

    /**
     * Project initialization logic to be executed even before VUE app starts
     */
    static async preloadConfigurations() {
        await this.loadMessageDict();
    }

    static processObjectFieldValue(obj: any, field: string, value?: any, setValue = false): any {
        if (obj && field && field !== "") {
            const fieldArr = String(field).split(".");
            let selectedObj = obj;
            let fieldHierarchy = 0;
            let selectedField = fieldArr[fieldHierarchy];
            let fieldValue;
            while (selectedObj && selectedField) {
                if (fieldArr.length - 1 === fieldHierarchy) {
                    if (setValue) selectedObj[selectedField] = value;
                    else fieldValue = selectedObj[selectedField];
                    break;
                }
                else {
                    fieldHierarchy++;
                    selectedObj = selectedObj[selectedField];
                    selectedField = fieldArr[fieldHierarchy];
                }
            }
            return fieldValue;
        }
        return obj;
    }

    static getConcatenatedValue(obj: any, values: { type: string, value: string }[]) {
        let concatenatedVal = "";
        for (const val of values) {
            if (val.type === "field") concatenatedVal += Helpers.processObjectFieldValue(obj, val.value)
            else concatenatedVal += val.value;
        }
        return concatenatedVal;
    }

    static getLocaleFormattedDate(date: Date) {
        return lx.DateTime.fromJSDate(date as Date).toFormat("yyyy-MM-dd'T'HH:mm:ss", {});
    }

    static getUTCNow() {
        return lx.DateTime.local().toUTC().toISO();
    }

    static getDateDifferenceInYears(fromDate: Date, toDate: Date) {
        const diff = lx.DateTime.fromJSDate(toDate as Date).diff(lx.DateTime.fromJSDate(fromDate as Date), ["years", "months", "days", "hours"])
        const diffObject = diff.toObject();
        return diffObject.years
    }
}



export const formattedDate = (dateVal: any) => {
    if (!dateVal) return
    const date: any = new Date(dateVal);
    if (isNaN(date)) return;
    const formatDate = (
        `${date.getMonth() > 8
            ? date.getMonth() + 1
            : `0${date.getMonth() + 1}`
        }/${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
        }/${date.getFullYear()}`
    );
    return formatDate;
}

export const formatMultidDate = (dateVal: any) => {

    if (!dateVal) return
    let retVal = ""
    if (typeof (dateVal) === "object") {
        const formatValue: any = []
        dateVal.forEach((element: string) => {
            if (!element) return
            const date: any = new Date(element);
            if (isNaN(date)) return;
            const formatDate = (
                `${date.getMonth() > 8
                    ? date.getMonth() + 1
                    : `0${date.getMonth() + 1}`
                }/${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
                }/${date.getFullYear()}`
            );
            formatValue.push(formatDate)
        });
        const value = formatValue.join(",")
        retVal = String(value);
    }
    else {
        if (!dateVal) return
        const date: any = new Date(dateVal);
        if (isNaN(date)) return;
        const formatDate = (
            `${date.getMonth() > 8
                ? date.getMonth() + 1
                : `0${date.getMonth() + 1}`
            }/${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
            }/${date.getFullYear()}`
        );
        retVal = String(formatDate);
    }
    return retVal
}

export const increaseDate = (date: any, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result
}

export const formattedTime = (dateVal: any) => {
    const date = new Date(dateVal);
    return date.toLocaleTimeString("en-GB", { hour12: false }
    );
}

export const formattedDateTime = (dateVal: any) => {
    const date = new Date(dateVal)
    const formatDate = (
        `${date.getMonth() > 8
            ? date.getMonth() + 1
            : `0${date.getMonth() + 1}`
        }/${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
        }/${date.getFullYear()}`
    );
    const formatTime = date.toLocaleTimeString("en-GB", { hour12: false });
    return formatDate.concat(" ", formatTime)
}

export const formatDateOnly = (dateVal: string) => {
    if (!dateVal) return
    const _date = dateVal.split("-");
    return `${_date[1]}/${_date[2]}/${_date[0]}`
}

export const formatConvertedDateTime = (dateVal: any) => {
    const date = convertedDate(dateVal);
    return formattedDateTime(date);
}

export function loadAppConfig() {
    return fetch("/assets/configs/global-config.json", {
        method: "GET",
        mode: "same-origin",
        referrerPolicy: "no-referrer"
    }).then((resp) => {
        return resp.json().then((g) => {
            localStorage.setItem("PREA_CONFIG", btoa(JSON.stringify(g)));
        })
    });
}

export function loadJsonAsset(fileName: string) {
    return fetch(`/assets/configs/${fileName}`, {
        method: "GET",
        mode: "same-origin",
        referrerPolicy: "no-referrer"
    });
}

function getValidField(field: string) {
    field = String(field)
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, "");
    field = field.replace(/\s\s+/g, " ");
    return String(field)
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, "_");
}

function isObject(obj: any) {
    return Object.prototype.toString.call(obj) === "[object Object]";
}

function flatObject(initField: string, obj: any, res: any) {
    for (const key of Object.keys(obj)) {
        let field = "";
        if (isObject(obj[key])) {
            field = field.slice(field.length - 1, 1);
        }
        if (initField !== "") field = initField + String("_");
        field += getValidField(key);
        if (Array.isArray(obj[key])) {
            for (let i = 0; i < obj[key].length; i++) {
                if (isObject(obj[key][i])) {
                    if (obj[key][i].id && obj[key][i].name) res[field + String("_") + getValidField(obj[key][i].name)] = true;
                    else flatObject(field + (i + 1), obj[key][i], res);
                }
                else res[field + String("_") + getValidField(obj[key][i])] = true;
            }
        }
        else if (isObject(obj[key])) {
            flatObject(field, obj[key], res);
        }
        else {
            res[field] = obj[key];
        }
    }
}

export function flatteningJson(jsonObj: any) {
    const res = {}
    flatObject("", jsonObj, res)
    return res;
}

export function ConvertIdNameToFieldOption(res: IdName<string>[]): FieldOption[] {
    const list: FieldOption[] = [];
    for (const item of res) list.push({
        value: item.id,
        text: item.name
    });
    return list;
}

export function ageCalculation(dob: string): number {
    if (dob) {
        const currentYear = new Date().getFullYear();
        const selectedYear = new Date(dob).getFullYear();
        return currentYear - selectedYear;
    }
    return 0;
}

export function isDate(date: any): boolean {
    if ((/\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z?)?/g).test(date) || (/^\d{2}\/\d{2}\/\d{4}.*$/).test(date)) {
        const resDate = date.toString()
        return (new Date(resDate).toString() !== "Invalid Date") && !isNaN(new Date(date).getDate());
    }
    return false
}

export const formattedDateTimeReports = (data: any) => {
    if (!data) return
    const date: any = new Date(data)
    if (isNaN(date)) return;
    const formatDate = (
        `${date.getMonth() > 8
            ? date.getMonth() + 1
            : `0${date.getMonth() + 1}`
        }/${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
        }/${date.getFullYear()}`
    );
    const formatTime = date.toLocaleTimeString("en-GB", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit"
    },
    );
    return formatDate.concat(" at ", formatTime);
}

const getAESPass = CryptoJS.enc.Hex.parse("000102030405060708090a0b0c0d0e0f").toString();
export const encryptedText = (val: any) => CryptoJS.AES.encrypt(val, getAESPass).toString();
export const decryptedText = (val: any) => CryptoJS.AES.decrypt(val, getAESPass).toString(CryptoJS.enc.Utf8)

export const getYearsbeforeDate = (val: string) => {
    const yearsbefore = parseInt(val, 10);
    const today = new Date();
    const beforeDate = new Date(today.setFullYear(today.getFullYear() - yearsbefore));
    return beforeDate;
}

export function calculateYearDifference(date1: string, date2: string): number | string {
    const validateFormat = (date: string): boolean => {
        const regex = /^\d{4}-\d{2}-\d{2}( |T)\d{2}:\d{2}:\d{2}$/;
        return regex.test(date);
    };

    if (!validateFormat(date1) || !validateFormat(date2) || (!date1)) {
        return "";
    }
    const formattedDate1 = date1.includes("T") ? date1 : date1.replace(" ", "T");
    const formattedDate2 = date2.includes("T") ? date2 : date2.replace(" ", "T");
    const d1 = new Date(formattedDate1);
    const d2 = new Date(formattedDate2);
    if (isNaN(d1.getTime()) || isNaN(d2.getTime())) {
        return 0;
    }
    let yearDifference = d1.getFullYear() - d2.getFullYear();
    if (d1.getMonth() < d2.getMonth() || (d1.getMonth() === d2.getMonth() && d1.getDate() < d2.getDate())) {
        yearDifference--;
    }
    return yearDifference;
}

export function getMonthDifference(date1: string, date2: string): { status: string; monthDifference?: number } {
    const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

    if (!datePattern.test(date1) || !datePattern.test(date2)) {
        return { status: "No" };
    }

    const parsedDate1 = new Date(date1);
    const parsedDate2 = new Date(date2);

    if (isNaN(parsedDate1.getTime()) || isNaN(parsedDate2.getTime())) {
        return { status: "No" };
    }

    if (
        parsedDate1.getFullYear() === parsedDate2.getFullYear() &&
        parsedDate1.getMonth() === parsedDate2.getMonth()
    ) {
        return {
            status: "Yes",
            monthDifference: 0
        };
    }

    const yearDiff = parsedDate1.getFullYear() - parsedDate2.getFullYear();
    const monthDiff = parsedDate1.getMonth() - parsedDate2.getMonth();
    const totalMonthDifference = yearDiff * 12 + monthDiff;

    return {
        status: "Yes",
        monthDifference: Math.abs(totalMonthDifference)
    };
}

export function isOnlySpaces(word: string): boolean {
    if (word === undefined || word === null) {
        return true
    }
    return word.trim() === "";
}

export function addingDays(date: string, daysToAdd: number): string {
    const dateObject = new Date(date);
    dateObject.setDate(dateObject.getDate() + daysToAdd);
    const formattedIsoDate = dateObject.toISOString().split(".")[0];
    return formattedIsoDate;
}

export function isString(input: any): boolean {
    return typeof input === "string";
}